<h1>SUCHE</h1>

<mat-chip-grid #chipGrid aria-label="Enter keywords" [formControl]="filterControl">
    <mat-chip-row *ngFor="let filter of selectedFilters" (removed)="removeFilter(filter)" [matTooltip]="filter.path">
        {{filter.displayName}}
        <button matChipRemove aria-label="'remove ' + filter">
            <mat-icon>cancel</mat-icon>
        </button>
    </mat-chip-row>
</mat-chip-grid>
<div class="search">
    <span class="fa fa-search" (click)="search()"></span>
    <input matInput type="text" [(ngModel)]="keyword" (keydown)="textChanged($event)" (blur)="this.updateUrl(); search();" placeholder="Bitte geben Sie hier Suchbegriffe ein">
</div>
@if (isAdmin) {
    <div class="filter">
        <mat-form-field matTooltip="Filtert die Suchergebnisse nach ausgewälten Filtern" class="filterbox" appearance="outline">
            <input #filterInput type="text" placeholder="Optionale Suchfilter" aria-label="Select filters" matInput [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [formControl]="filterControl">
            @if (!filterInput.value) {
                <button [disableRipple]="true" matSuffix mat-icon-button aria-label="Filter">
                    <mat-icon >filter_list</mat-icon>
                </button>
            } @else {
                <button [disableRipple]="true" matSuffix mat-icon-button aria-label="Clear" (click)="filterInput.value=null">
                    <mat-icon >close</mat-icon>
                </button>
            }
        </mat-form-field>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFilters">
            <mat-option *ngFor="let filter of filters | async">
                <div (click)="optionClicked($event, filter)">
                    <mat-checkbox [disableRipple]="true" [checked]="selectedFilters.includes(filter)">
                        {{filter.displayName}} [{{filter.typeString}}]
                        <br>
                        <span class="mat-caption">{{filter.path}}</span>
                    </mat-checkbox>
                </div>
            </mat-option>
        </mat-autocomplete>
    </div>
}
<mat-checkbox class="all-fleets-checkbox mb-3" (change)="searchAllFleets = $event.checked">Flottenübergreifend suchen</mat-checkbox>
@if (isAdmin) {
    <mat-checkbox matTooltip="Erlaubt das verwenden von [FILTER]=wert direkt in der Suche z.B. evse.name=Name" class="all-fleets-checkbox mb-3" (change)="advanced = $event.checked">
        Erweiterte Suche aktivieren
    </mat-checkbox>
}
<br/>

<span class="result-count" *ngIf="showResultCount && !showLoading">{{getTotalSearchResultCount()}} Ergebnisse</span>

<h1 *ngIf="showLoading" style="text-align: center">
    <i class="fas fa-circle-notch fa-spin"></i>
</h1>

<table mat-table [dataSource]="dataSource" class="full-width-table" matSort *ngIf="dataSource.data.length !== 0">
    <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef class="col-result">Gefunndene Objekte</th>
        <td mat-cell *matCellDef="let element" class="col-result" style="width: 700px">
            <a class="c-blue-1" routerLink="{{createRouterLink(element)}}">
                {{createTitle(element)}}
            </a>
            <table class="result-details w-auto">
                <tr *ngFor="let f of element.fields">
                    <td>{{f.fieldDisplayName}}: </td>
                    <td><span *ngFor="let s of f.details.value" [class.partial-match]="s.partialMatch" [class.full-match]="s.fullMatch">{{s.text}}</span></td>
                </tr>
                <tr *ngIf="searchAllFleets">
                    <td colspan="2">Flotte: {{element.fleetName}}</td>
                </tr>
            </table>
        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="col-links">Aktionen</th>
        <td mat-cell *matCellDef="let element" class="col-links">
            <span class="evse-validation" *ngIf="element.resultType == 'evse' && getValidations(element.object)">
                <button mat-icon-button matTooltip="Fehler in Stationskonfiguration" (click)="handleValidation(element.object)">
                    <mat-icon>sms_failed</mat-icon>
                </button>
            </span>

            <span *ngIf="element.resultType == 'evse' && element.object.type === 'Easee Home'">
                <button mat-icon-button matTooltip="Token erzeugen" (click)="easeeLogin(element)">
                    <mat-icon>login</mat-icon>
                </button>
            </span>

            <button mat-icon-button matTooltip="RFID bis zum Abstecken abschalten" *ngIf="element.resultType == 'evse' && showUnlockButton(element.object)" (click)="unlock(element.object)">
                <mat-icon
                    class="iblack">
                    last_page
                </mat-icon>
            </button>

            <button mat-icon-button matTooltip="Fahrzeugerkennung starten" *ngIf="element.resultType == 'evse' && element.object.canEdit && element.object.sequencesAllowed" (click)="triggerSequence(element.object)">
                <mat-icon
                    class="iblack"
                    svgIcon="plug_sequence">
                </mat-icon>
            </button>
            <button mat-icon-button matTooltip="Aktionen" *ngIf="element.resultType == 'evse'" (click)="actions(element.object)">
                <mat-icon
                    class="iblack">
                    touch_app
                </mat-icon>
            </button>

            <button *ngIf="element.resultType == 'solarSystem'" mat-raised-button color="primary" (click)="addSolarPanel(element)">Neues Solarpanel</button>
            <button [disabled]="!element.object.canEdit" (click)="edit(element)" mat-icon-button matTooltip="Bearbeiten"><mat-icon>edit</mat-icon></button>
            <button *ngIf="element.resultType == 'user'" [disabled]="!element.object.canEdit" (click)="editPw(element)" mat-icon-button matTooltip="Passwort ändern">
                <mat-icon fontSet="material-icons">lock</mat-icon>
            </button>
            <button [disabled]="!element.object.canDelete" (click)="delete(element)" mat-icon-button matTooltip="Löschen"><mat-icon>delete</mat-icon></button>
        </td>
    </ng-container>

    <ng-container matColumnDef="links">
        <th mat-header-cell *matHeaderCellDef class="col-links">Verwante Objekte</th>
        <td mat-cell *matCellDef="let element" class="col-links">
            <span class="relevant-text">Relevant</span>
            <div [innerHTML]="createSupportingLinks(element)">
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons *ngIf="dataSource.data.length !== 0">
</mat-paginator>
