import {Component, inject, Inject, model} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EvseDialogData} from "../evse-log-dialog/evse-log-dialog.component";
import {Evse} from "@io-elon-common/frontend-api";
import {EvseService} from "../../service/evse.service";
import {BehaviorSubject} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {DialogType} from '../../../../shared/components/help-box/dialogType';
import {EvseTableComponent, EvseFilterData, EvseFilterCategory} from '../../components/evse-table/evse-table.component';

@Component({
    selector: 'app-evse-filter-dialog',
    templateUrl: './evse-filter-dialog.component.html',
    styleUrl: './evse-filter-dialog.component.scss'
})
export class EvseFilterDialogComponent {

    readonly dialogRef = inject(MatDialogRef<EvseTableComponent>);
    readonly data = inject<EvseFilterData[]>(MAT_DIALOG_DATA);
    readonly filters = model(this.data);

    onNoClick(): void {
        this.dialogRef.close();
    }

    protected readonly EvseFilterCategory = EvseFilterCategory;
}
