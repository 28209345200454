import {Injectable} from '@angular/core';
import {EvseActorArgs} from '@io-elon-common/frontend-api';
import {isArray} from 'rxjs/internal-compatibility';
import {SearchDataConfig} from './searchDataConfig';
import {SearchFilterConfig} from './searchFilterConfig';
import {FilterType, SearchFilter} from './searchFilter';

export type SearchType = "vehicle" | "evse" | "rfid" | "meter" | "powerSupply" | "load" | "solarSystem" | "user"

export interface ResultDetails {
    value: Array<{
        text: string,
        partialMatch: boolean,
        fullMatch: boolean
    }>
}

export type Matches = {
    //Found full query string as word (whitespace or string end before & after)
    fullWord: number;
    //Fund full query string
    full: number;
    //Fund single words of the query string
    partial: number;
};

export function compareMatches(a: Matches, b:Matches) {
    const fw = b.fullWord - a.fullWord;
    if(fw !== 0) {
        return fw;
    }
    const w = b.full - a.full;
    if(w !== 0) {
        return w;
    }
    return b.partial - a.partial;
}

export function hasAnyMatch(m: Matches): boolean {
    return !!(m.fullWord || m.full || m.partial);
}
export function containsAnyMatch(results: FieldResult[]|SearchResult[]): boolean {
    return results.find(res=> hasAnyMatch(res.matches)) !== undefined;
}

export interface SearchResult {
    object: any,
    matches: Matches
    resultType: SearchType,
    links: Set<string>,
    fields: FieldResult[],
    fleetName: string | undefined
}

export interface FieldResult {
    field: string;
    fieldDisplayName: string;
    value: string;
    matches: Matches,
    details: ResultDetails
}
@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor(
        private readonly searchDataConfig: SearchDataConfig,
        private readonly searchFilterConfig: SearchFilterConfig
    ) {
    }

    public searchFields(keyword: string, obj: any, parentPath: string, filters: SearchFilter[] = this.searchFilterConfig.allFilter): FieldResult[] {
        const result: Array<FieldResult | FieldResult[] | null> = Object.keys(obj).map(fieldName => {
            const value = obj[fieldName];
            const fieldPath = parentPath + "." + fieldName;

            if (this.searchDataConfig.isFieldIgnored(fieldPath)) {
                return null;
            }

            //Special handling for actor Args, the have name by key, not by property name
            if(fieldPath.endsWith("actorArgs")) {
                return this.searchActorArgs(keyword, value as any[], fieldPath, filters)
            }

            if (typeof value === 'object') {
                return this.searchFields(keyword, value, fieldPath, filters);
            }

            const fieldDisplayName = this.searchDataConfig.getDisplayName(fieldPath);
            if(fieldDisplayName === null) {
                //This field is not searchable
                return null;
            }
            if(filters.length>0 && filters.find(f=> f.match(fieldPath)) === undefined) {
                return null
            }

            const matches = this.searchString(keyword, "" + value);

            if (!hasAnyMatch(matches)) {
                return null;
            }

            return {
                field: fieldPath,
                fieldDisplayName: fieldDisplayName,
                value: value,
                matches,
                details: this.createDetailsText(keyword, ""+value)
            };
        });

        return this.flatten(result)
    }

    private searchString(keyword: string, val: string): Matches {
        // Search for fullWord matches
        const parts = val.toUpperCase().split(keyword.toUpperCase());

        const full = parts.length - 1;
        let fullWord = 0;

        // Start at 1, we do forward and backword lookup
        for(let i = 1; i < parts.length; i++) {
            // empty or ends with space
            const validBefore = /(^|\s)$/.test(parts[i-1]);
            // empty or starts with space
            const validAfter = /^(\s|$)/.test(parts[i]);

            if(validBefore && validAfter) {
                fullWord++;
            }
        }

        const keyParts = keyword.toUpperCase().split(/\s/);

        let partial = 0;
        for(const k of keyParts) {
            if(k.trim().length === 0) {
                continue;
            }

            partial += val.toUpperCase().split(k).length - 1;
        }

        return {fullWord, full, partial}
    }

    private searchActorArgs(keyword: string, actorArgs: EvseActorArgs[], fieldPath: string, filters: SearchFilter[]): FieldResult[] {
        return actorArgs
            .filter(arg => filters.length === 0 || filters.find(f => f.match(arg.key)))
            .map(a => {
                const displayName = this.searchDataConfig.getActorArgDisplayName(a.key);
                return {
                    field: fieldPath + "." + a.key,
                    fieldDisplayName: displayName,
                    value: a.value,
                    matches: this.searchString(keyword, a.value),
                    details: this.createDetailsText(keyword, a.value)
                };
            }).filter(f => hasAnyMatch(f.matches));
    }

    private flatten<T>(arr: Array<T |T[] | null>): T[] {
        const ret: T[] = [];
        for(const a of arr) {
            if(a === null) {
                continue;
            }
            if(isArray(a)) {
                ret.push(...a);
            } else {
                ret.push(a);
            }
        }
        return ret;
    }

    public createDetailsText(keyword: string, value: string): ResultDetails {
        const fullStart = value.toUpperCase().indexOf(keyword.toUpperCase());

        if(fullStart !== -1) {
            //We have a full match, underline it
            return {
                value: [{
                    text: value.substring(0, fullStart),
                    partialMatch: false,
                    fullMatch: false
                }, {
                    text: value.substring(fullStart, fullStart + keyword.length),
                    partialMatch: false,
                    fullMatch: true
                }, {
                    text: value.substring(fullStart + keyword.length),
                    partialMatch: false,
                    fullMatch: false
                }]
            }
        } else {
            //We have partial matches, bold them
            const keyParts = keyword.toUpperCase().split(/\s/);
            const valueArr: ResultDetails["value"] = [];

            valueArr.push({
                text: "",
                partialMatch: false,
                fullMatch: false,
            });

            for(let i = 0; i < value.length; i++) {
                const last = valueArr[valueArr.length - 1];
                const bold = keyParts.some(k => {
                    const len = k.length;
                    const idx = value.toUpperCase().indexOf(k, i-len);
                    return idx !== -1 && idx <= i;
                });
                if(bold === last.partialMatch) {
                    last.text += value[i];
                } else {
                    valueArr.push({
                        text: value[i],
                        partialMatch: bold,
                        fullMatch: false
                    });
                }
            }

            return {
                value: valueArr
            }
        }
    }
}
