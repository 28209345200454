import {Injectable} from '@angular/core';
import {SystemService} from '../../services/api-handlers/system.service';
import {FilterType, SearchFilter} from './searchFilter';


@Injectable({
    providedIn: 'root'
})
export class SearchFilterConfig {


    constructor(private readonly systemInfo: SystemService) {
    }
    public get allFilter(): Array<SearchFilter> {
        return Array.of(
            ...this.customFilters,
            ...this.objectFilters,
            ...this.fieldFilters,
            ...this.actorArgFilters);
    }

    public get actorArgFilters(): Array<SearchFilter> {
        const filters: SearchFilter[] = [];
        const supportedEvses = this.systemInfo.getSystemInfoSync()?.supportedEvses ?? [];
        for(const evse of supportedEvses) {
            for(const actor of evse.actors) {
                for(const arg of actor.args) {
                    if (!this.includes(arg.key, filters)) {
                        filters.push(SearchFilter.newActorArgFilter(arg.key, arg.name));
                    }
                }
            }
        }
        return filters;
    }

    public customFilters: Array<SearchFilter> = [];

    public objectFilters: Array<SearchFilter> = Array.of(
        SearchFilter.newObjectFilter( "evse", "Evse"),
        SearchFilter.newObjectFilter("load", "Last"),
        SearchFilter.newObjectFilter("meter", "Zähler"),
        SearchFilter.newObjectFilter("powerSupply", "Anschluss"),
        SearchFilter.newObjectFilter("rfid", "RFID Karte"),
        SearchFilter.newObjectFilter("user", "Benutzer"),
        SearchFilter.newObjectFilter("vehicle", "Fahrzeug"),

    );
    public fieldFilters: Array<SearchFilter> = Array.of(
        SearchFilter.newFieldFilter("evse.actorType", "Typ der Anbindung"),
        SearchFilter.newFieldFilter("evse.guestMinAmps","Minimaler Strom für Gastfahrzeuge"),
        SearchFilter.newFieldFilter("evse.id", "ID"),
        SearchFilter.newFieldFilter("evse.liveData.evseCosPhi1.val", "Cosinus Phi (Leistungsfaktor 1)"),
        SearchFilter.newFieldFilter("evse.liveData.evseCosPhi1.val", "Cosinus Phi (Leistungsfaktor 1)"),
        SearchFilter.newFieldFilter("evse.liveData.evseCosPhi2.val", "Cosinus Phi (Leistungsfaktor 2)"),
        SearchFilter.newFieldFilter("evse.liveData.evseCosPhi3.val", "Cosinus Phi (Leistungsfaktor 3)"),
        SearchFilter.newFieldFilter("evse.liveData.evseI1.val", "Strom (I1)"),
        SearchFilter.newFieldFilter("evse.liveData.evseI2.val", "Strom (I2)"),
        SearchFilter.newFieldFilter("evse.liveData.evseI3.val", "Strom (I3)"),
        SearchFilter.newFieldFilter("evse.liveData.evseP1.val", "Leistung (P1)"),
        SearchFilter.newFieldFilter("evse.liveData.evseP2.val", "Leistung (P2)"),
        SearchFilter.newFieldFilter("evse.liveData.evseP3.val", "Leistung (P3)"),
        SearchFilter.newFieldFilter("evse.liveData.evseU1.val", "Leistung (U1)"),
        SearchFilter.newFieldFilter("evse.liveData.evseU2.val", "Leistung (U2)"),
        SearchFilter.newFieldFilter("evse.liveData.evseU3.val", "Leistung (U3)"),
        SearchFilter.newFieldFilter("evse.liveData.evseDetectedMaxAmps.val", "Maxmialstrom (Erkannt)"),
        SearchFilter.newFieldFilter("evse.liveData.evseFirmwareVersion.val", "Firmware Version"),
        SearchFilter.newFieldFilter("evse.liveData.evseIecStatus.val", "IEC Status"),
        SearchFilter.newFieldFilter("evse.liveData.evseLastBootnotification.val", "Letzte BootNotification (OCPP)"),
        SearchFilter.newFieldFilter("evse.liveData.evseModel.val", "Model"),
        SearchFilter.newFieldFilter("evse.liveData.evseRfidToken.val", "RFID Token"),
        SearchFilter.newFieldFilter("evse.liveData.evseSerialNumber.val", "Seriennummer"),
        SearchFilter.newFieldFilter("evse.liveData.evseTargetI1.val", "Zielstrom"),
        SearchFilter.newFieldFilter("evse.liveData.healthStatus.val", "Status"),
        SearchFilter.newFieldFilter("evse.liveData.ocppRemoteType.source", "Quelle für Erkannten OCPP Typ"),
        SearchFilter.newFieldFilter("evse.liveData.ocppRemoteType.val", "Erkannter OCPP Typ"),
        SearchFilter.newFieldFilter("evse.liveData.validationResult.X.ruleDisplayName", "Config Checker Regel"),
        SearchFilter.newFieldFilter("evse.liveData.vehicle.dataSource.displayName", "Name der Telemetrie/API des verbundenen Fahrzeuges"),
        SearchFilter.newFieldFilter("evse.liveData.vehicle.dataSource.id", "ID der Telemetrie/API des verbundenen Fahrzeuges"),
        SearchFilter.newFieldFilter("evse.liveData.vehicle.id", "ID des angeschlossenen Fahrzeuges"),
        SearchFilter.newFieldFilter("evse.liveData.vehicle.localId", "Lokale ID des angeschlossenen Fahrzeuges"),
        SearchFilter.newFieldFilter("evse.liveData.vehicle.name", "Name des angeschlossenen Fahrzeuges"),
        SearchFilter.newFieldFilter("evse.liveData.vehicle.numberPlate", "Nummernschild des angeschlossenen Fahrzeuges"),
        SearchFilter.newFieldFilter("evse.liveData.vehicle.type", "Typ des angeschlossenen Fahrzeuges"),
        SearchFilter.newFieldFilter("evse.liveData.vehicle.vehicleClass", "Art des angeschlossenen Fahrzeuges"),
        SearchFilter.newFieldFilter("evse.localId", "Lokale ID"),
        SearchFilter.newFieldFilter("evse.maintenanceInitiator.firstname", "Name des Wartungsmodus-Initiators"),
        SearchFilter.newFieldFilter("evse.maintenanceInitiator.lastname", "Name des Wartungsmodus-Initiators"),
        SearchFilter.newFieldFilter("evse.maintenanceInitiator.name", "Name des wartungsmodus Initiators"),
        SearchFilter.newFieldFilter("evse.maintenanceReason", "Grund der Wartung"),
        SearchFilter.newFieldFilter("evse.maxI", "Maximaler Strom"),
        SearchFilter.newFieldFilter("evse.modelMaxAmps", "Maximaler Strom (Bauart)"),
        SearchFilter.newFieldFilter("evse.phaseRotation", "Phasenrotation"),
        SearchFilter.newFieldFilter("evse.typeKey", "Typ"),
        SearchFilter.newFieldFilter("evse.type", "Typ"),
        SearchFilter.newFieldFilter("evse.name", "Name"),
        SearchFilter.newFieldFilter("load.formula", "Formel"),
        SearchFilter.newFieldFilter("load.id", "ID"),
        SearchFilter.newFieldFilter("load.name", "Name"),
        SearchFilter.newFieldFilter("load.type", "Typ"),
        SearchFilter.newFieldFilter("load.meterId", "ID des zugeordneten Smartmeters"),
        SearchFilter.newFieldFilter("load.powerSupplyId", "ID der zugeordneten Unterverteilung"),
        SearchFilter.newFieldFilter("load.zone", "Zone"),
        SearchFilter.newFieldFilter("meter.hostname", "Hostname / IP-Adresse"),
        SearchFilter.newFieldFilter("meter.id", "ID"),
        SearchFilter.newFieldFilter("meter.modbusId", "Modbus ID"),
        SearchFilter.newFieldFilter("meter.name", "Name"),
        SearchFilter.newFieldFilter("meter.port", "Port"),
        SearchFilter.newFieldFilter("meter.type", "Typ"),
        SearchFilter.newFieldFilter("powerSupply.id", "ID"),
        SearchFilter.newFieldFilter("powerSupply.maxAmps", "Maximaler Strom"),
        SearchFilter.newFieldFilter("powerSupply.name", "Name"),
        SearchFilter.newFieldFilter("powerSupply.userDefinedPeak", "Benutzerdefinierter Peak"),
        SearchFilter.newFieldFilter("rfid.id", "ID"),
        SearchFilter.newFieldFilter("rfid.invoiceReceiver", "Kostenstelle"),
        SearchFilter.newFieldFilter("rfid.name", "Name"),
        SearchFilter.newFieldFilter("rfid.token", "Token"),
        SearchFilter.newFieldFilter("rfid.vehicle.id", "ID des zugeordneten Fahrzeuges"),
        SearchFilter.newFieldFilter("rfid.vehicle.localId", "Lokale ID des zugeordneten Fahrzeuges"),
        SearchFilter.newFieldFilter("rfid.vehicle.name", "Name des zugeordneten Fahrzeuges"),
        SearchFilter.newFieldFilter("rfid.vehicle.numberPlate", "Nummernschild des zugeordneten Fahrzeuges"),
        SearchFilter.newFieldFilter("user.email", "E-Mail"),
        SearchFilter.newFieldFilter("user.id", "ID"),
        SearchFilter.newFieldFilter("user.firstname", "Vorname"),
        SearchFilter.newFieldFilter("user.lastname", "Nachname"),
        SearchFilter.newFieldFilter("user.name", "Benutzername"),
        SearchFilter.newFieldFilter("vehicle.confMaxAmps", "Maximaler Strom (Config)"),
        SearchFilter.newFieldFilter("vehicle.confMinAmps", "Minimaler Strom (Config)"),
        SearchFilter.newFieldFilter("vehicle.dataSource.displayName", "Name der Telematik/API"),
        SearchFilter.newFieldFilter("vehicle.dataSource.id", "ID der Telematik/API"),
        SearchFilter.newFieldFilter("vehicle.dataSource.vendorVehicleId", "Kennung des Herstellers für die Telematik/API"),
        SearchFilter.newFieldFilter("vehicle.defaultTargetSoc", "Ziel Ladestand"),
        SearchFilter.newFieldFilter("vehicle.id", "ID"),
        SearchFilter.newFieldFilter("vehicle.liveData.i1.val", "Strom (I1)"),
        SearchFilter.newFieldFilter("vehicle.liveData.i2.val", "Strom (I2)"),
        SearchFilter.newFieldFilter("vehicle.liveData.i3.val", "Strom (I3)"),
        SearchFilter.newFieldFilter("vehicle.liveData.p1.val", "Leistung (P1)"),
        SearchFilter.newFieldFilter("vehicle.liveData.p2.val", "Leistung (P2)"),
        SearchFilter.newFieldFilter("vehicle.liveData.p3.val", "Leistung (P3)"),
        SearchFilter.newFieldFilter("vehicle.liveData.u1.val", "Spannung (U1)"),
        SearchFilter.newFieldFilter("vehicle.liveData.u2.val", "Spannung (U2)"),
        SearchFilter.newFieldFilter("vehicle.liveData.u3.val", "Spannung (U3)"),
        SearchFilter.newFieldFilter("vehicle.liveData.u12Meas.val", "Spannung (12V Batterie)"),
        SearchFilter.newFieldFilter("vehicle.liveData.availState", "Verfügbarkeitsstatus"),
        SearchFilter.newFieldFilter("vehicle.liveData.errorState", "Fehlerstatus"),
        SearchFilter.newFieldFilter("vehicle.liveData.estimatedSocR.val", "Geschätzter Ladestand (Real)"),
        SearchFilter.newFieldFilter("vehicle.liveData.estimatedSocU.val", "Geschätzter Ladestand"),
        SearchFilter.newFieldFilter("vehicle.liveData.i.val", "Strom"),
        SearchFilter.newFieldFilter("vehicle.liveData.iCp.val", "Zielstrom"),
        SearchFilter.newFieldFilter("vehicle.liveData.odometer.val", "Kilometerzähler"),
        SearchFilter.newFieldFilter("vehicle.liveData.p.val", "Leistung"),
        SearchFilter.newFieldFilter("vehicle.liveData.pwrState.val", "Status"),
        SearchFilter.newFieldFilter("vehicle.liveData.pwrText", "Status"),
        SearchFilter.newFieldFilter("vehicle.liveData.rangeFull.val", "Reichweite bei 100%"),
        SearchFilter.newFieldFilter("vehicle.liveData.rangeNow.val", "Reichweite"),
        SearchFilter.newFieldFilter("vehicle.liveData.rssi.val", "Signalstärke (RSSI)"),
        SearchFilter.newFieldFilter("vehicle.liveData.socLimit.val", "Ladestand Limit"),
        SearchFilter.newFieldFilter("vehicle.liveData.socR.val", "Ladestand (Real)"),
        SearchFilter.newFieldFilter("vehicle.liveData.socU.val", "Ladestand"),
        SearchFilter.newFieldFilter("vehicle.liveData.soh.val", "Batteriegesundheit"),
        SearchFilter.newFieldFilter("vehicle.liveData.targetSocR", "Zielladestand (Real)"),
        SearchFilter.newFieldFilter("vehicle.liveData.targetSocU", "Zielladestand"),
        SearchFilter.newFieldFilter("vehicle.liveData.u.val", "Spannung"),
        SearchFilter.newFieldFilter("vehicle.maxAmps", "Maximaler Stromg"),
        SearchFilter.newFieldFilter("vehicle.numPhases", "Anzahl Phasen"),
        SearchFilter.newFieldFilter("vehicle.localId", "Lokale ID"),
        SearchFilter.newFieldFilter("vehicle.name", "Name"),
        SearchFilter.newFieldFilter("vehicle.numberPlate", "Nummernschild"),
        SearchFilter.newFieldFilter("vehicle.type", "Typ"),
        SearchFilter.newFieldFilter("vehicle.vehicleClass", "Fahrzeugart")
    );

    public addCustomFilter(path: string, displayName: string, customFilter: (searchPath: string, filterpath: string) => boolean) {
        this.customFilters.push(SearchFilter.newCustomFilter(path, displayName, customFilter));
    }

    public evseFilter(): Array<SearchFilter> {
        return this.filter("evse");
    }
    public includes(searchPath: string, filters:Array<SearchFilter> = this.allFilter): boolean {
        const pathWithoutIdx = searchPath.replace(/\.\d+\./, ".X.");
        return filters.find(f => f.match(pathWithoutIdx)) !== undefined;
    }
    public find(searchPath: string, filters:Array<SearchFilter> = this.allFilter): SearchFilter|undefined {
        const pathWithoutIdx = searchPath.replace(/\.\d+\./, ".X.");
        return filters.find(f => f.match(pathWithoutIdx));
    }
    public filter(searchPath: string, filters:Array<SearchFilter> = this.allFilter): Array<SearchFilter> {
        const pathWithoutIdx = searchPath.replace(/\.\d+\./, ".X.");
        return filters.filter(f => f.match(pathWithoutIdx));
    }
    public filterByType(filterType: FilterType, filters: Array<SearchFilter>): Array<SearchFilter> {
        return filters.filter(f=> f.type === filterType);
    }
}
