
export enum FilterType {
    object,
    field,
    custom,
    actorArg
}

export class SearchFilter {

    constructor(
        public readonly path: string,
        public readonly displayName: string,
        public readonly type: FilterType,
        public readonly customFilter: ((searchPath: string, filterPath: string) => boolean) | undefined = undefined) {
    }

    public static newFieldFilter(path: string, displayName: string): SearchFilter {
        return new SearchFilter(path, displayName, FilterType.field);
    }

    public static newObjectFilter(path: string, displayName: string): SearchFilter {
        return new SearchFilter(path, displayName, FilterType.object);
    }

    public static newActorArgFilter(path: string, displayName: string): SearchFilter {
        return new SearchFilter(path, displayName, FilterType.actorArg);
    }

    public static newCustomFilter(path: string, displayName: string, customFilter: (searchPath: string, filterpath: string) => boolean): SearchFilter {
        return new SearchFilter(path, displayName, FilterType.custom, customFilter);
    }
    public isObject(): boolean {
        return this.type === FilterType.object;
    }

    public get typeString(): string {
        switch(this.type) {
            case FilterType.object: return "Typ";
            case FilterType.field: return "Datenfeld"
            case FilterType.actorArg: return "Aktorfeld"
            case FilterType.custom: return "Custom"
        }
    }

    public match(searchPath: string): boolean {
        const pathWithoutIdx = searchPath.replace(/\.\d+\./, ".X.");
        switch (this.type) {
            case FilterType.field:
            case FilterType.actorArg:  return pathWithoutIdx === this.path;
            case FilterType.object: return pathWithoutIdx.startsWith(this.path);
            case FilterType.custom: return this.customFilter === undefined? false : this.customFilter(pathWithoutIdx, this.path);
            default: return false;
        }
    }
    public includes(substring: string): boolean {
        return this.path.includes(substring) || this.displayName.includes(substring)
    }
}
