import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SystemService} from "../../services/api-handlers/system.service";
import {filter} from 'rxjs/operators';
import { SearchFilterConfig } from './searchFilterConfig';
export interface keyValuePair {
    key: string,
    value: string
}

@Injectable({
    providedIn: 'root'
})
export class SearchDataConfig {

    constructor(private readonly systemInfo: SystemService, private readonly searchFilterConfig: SearchFilterConfig) {
    }

    private ignoreSearchVariable = ['user.permissionGroups', 'user.vehicleCommands'];

    public getActorArgDisplayName(key: string) {
        const supportedEvses = this.systemInfo.getSystemInfoSync()?.supportedEvses ?? [];
        for(const evse of supportedEvses) {
            for(const actor of evse.actors) {
                for(const arg of actor.args) {
                    if(arg.key === key) {
                        return arg.name;
                    }
                }
            }
        }
        return key;
    }

    public getDisplayName(path: string): string | null {
       const result = this.searchFilterConfig.find(path, this.searchFilterConfig.fieldFilters);
       return result === undefined? null: result.displayName;
    }

    public getRoutingLinkReference(variable: string, object: any): keyValuePair[] | undefined {
        const result = [];
        switch (variable) {
            case 'vehicle': {
                if (object.evse) {
                    result.push({key: object.evse.name, value: this.getRouterLink("evse", object.evse.id) })
                }
                break;
            }
            case 'evse': {
                if (object.liveData && object.liveData.vehicle) {
                    result.push({key: object.liveData.vehicle.name, value: this.getRouterLink("vehicle", object.liveData.vehicle.id)})
                }
                break;
            }
            case 'rfid': {
                if (object.vehicle) {
                    result.push({key: object.vehicle.name, value: this.getRouterLink("vehicle", object.vehicle.id)})
                }
                if (object.user) {
                    result.push({key: object.user.name, value: this.getRouterLink("user", undefined)})
                }
                break;
            }
            default: return undefined;
        }
        return result;
    }

    public getRouterLink(resultType: string, id: string | undefined): string {
        switch (resultType) {
            case 'evse': return  "/evses/evse/" + id;
            case 'meter': return  "/meters/meter/" + id;
            case 'powerSupply': return  "/basis";
            case 'rfid': return  "/rfid";
            case 'vehicle': return  "/fleet/vehicle/" + id;
            case 'load': return  "/loads/";
            case 'solarSystem': return  "/solar/";
            case 'user': return  "/user/";
            default: return "Fehler";
        }
    }

    public getTitle(resultType: string, object: any): string {
        switch (resultType) {
            case 'vehicle': return `Fahrzeug: ${object.name} (${object.type})`;
            case 'evse': return `Ladepunkt: ${object.name} (${object.type})`;
            case 'rfid': return `RFID: ${object.name} (${object.token})` + (object.Kostenstelle ? ", Kostenstelle: " + object.Kostenstelle : "");
            case 'meter': return `Zähler: ${object.name} (${object.type})`;
            case 'powerSupply': return "Untervereilung: " + object.name;
            case 'load': return `Last: ${object.name} (${object.type})`;
            case 'solarSystem': return `Solaranlage: ${object.name} (via ${object.solarPredictionProvider})`;
            case 'user': return `Benutzer: ${object.name} (${object.name} / ${object.firstname} ${object.lastname})`;
            default: return "Fehler";
        }
    }

    isFieldIgnored(fieldName: string): boolean {
        return this.ignoreSearchVariable.includes(fieldName);
    }
}
