import {Component, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {VehicleService} from '../../vehicle/service/vehicle.service';
import {
    Evse,
    Fleet,
    Load,
    Meter,
    PowerSupply,
    Rfid,
    SolarSystem,
    User,
    Vehicle
} from '@io-elon-common/frontend-api';
import {AbstractTableComponent} from '../../../shared/components/tables/AbstractTableComponent';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {EvseService} from '../../evse/service/evse.service';
import {RfidService} from '../../rfid/service/rfid.service';
import {MeterService} from '../../meter/service/meter.service';
import {PowerSupplyService} from '../../basis/service/power-supply.service';
import {LoadService} from '../../loads/service/load.service';
import {Observable, Subscription} from 'rxjs';
import {FleetService} from '../../vehicle/service/fleet.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SolarSystemService} from '../../solar/service/solar-system.service';
import {UserService} from '../../users/service/user.service';
import {BasisService} from '../../basis/service/basis.service';
import {SolarPanelService} from '../../solar/service/solar-panel.service';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {
    SearchService,
    SearchType,
    SearchResult,
    compareMatches,
    hasAnyMatch
} from '../../../shared/search/search.service';
import {SearchFilter} from '../../../shared/search/searchFilter';
import {SearchDataConfig} from '../../../shared/search/searchDataConfig';
import {SearchFilterConfig} from '../../../shared/search/searchFilterConfig';
import {AuthService} from '../../../shared/guards/auth.service';

@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})

export class SearchViewComponent extends AbstractTableComponent implements OnInit, OnDestroy {

    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    displayedColumns: string[] = ['result', 'action', 'links'];
    dataSource = new MatTableDataSource<SearchResult>([]);
    selection = new SelectionModel<SearchResult>(true, []);

    filterControl = new FormControl();
    selectedFilters: SearchFilter[] = [];
    filters: Observable<SearchFilter[]> = new Observable();
    lastFilter: string = '';

    public keyword: string = "";
    public advanced = false;
    public showResultCount: boolean = false;
    public showLoading: boolean = false;
    public isAdmin = false;

    private _searchAllFleets = false;
    private cid: any;
    private vehicles: Vehicle[] = [];
    private evses: Evse[] = [];
    private rfids: Rfid[] = [];
    private meters: Meter[] = [];
    private powerSupplies: PowerSupply[] = [];
    private searchResults: SearchResult[] = [];
    private solarSystems: SolarSystem[] = [];
    private users: User[] = [];
    private loads: Load[] = [];
    private selectedFleetSubscription!: Subscription;
    private selectedBasisSubscription!: Subscription;
    private fleetId: number | undefined;
    private basisId: number | undefined;
    private headerKeyObservable: Subscription | undefined;
    private fleets: Fleet[] = [];


    constructor(
        private readonly vehicleService: VehicleService,
        private readonly evseService: EvseService,
        private readonly rfidService: RfidService,
        private readonly meterService: MeterService,
        private readonly powerSupplyService: PowerSupplyService,
        private readonly searchDataConfig: SearchDataConfig,
        private readonly searchFilterConfig: SearchFilterConfig,
        private readonly searchService: SearchService,
        private readonly loadService: LoadService,
        private readonly solarSystemService: SolarSystemService,
        private readonly route: ActivatedRoute,
        private readonly fleetService: FleetService,
        private readonly userService: UserService,
        private readonly basisService: BasisService,
        private readonly solarPanelService: SolarPanelService,
        private readonly dialog: MatDialog,
        private readonly toastrService: ToastrService,
        private readonly router: Router,
        private readonly authService: AuthService
        ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.showLoading = true;
        this.isAdmin = this.authService.isAdmin();
        this.fleets = await this.fleetService.getAllPromise();

        this.filters = this.filterControl.valueChanges.pipe(
            startWith<string | SearchFilter[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(fs => {
                this.lastFilter = fs;
                return this.searchFilterConfig.allFilter.filter(f=>f.includes(fs));
            })
        );

        this.cid = this.route.snapshot.queryParams.cid;

        this.route.queryParams.subscribe((queryParams: any) => {
            if(queryParams.search) {
                this.keyword=decodeURIComponent(queryParams.search);
                this.search();
            }
            if(queryParams.cid) {
                if(this.cid !== queryParams.cid) {
                    this.cid = queryParams.cid;
                    (async () => {
                        this.showLoading = true;
                        try {
                            this.dataSource.data = [];
                            await this.loadAllData();
                            this.search();
                        } finally {
                            this.showLoading = false;
                        }
                    })();
                }
            }
        });

        this.selectedFleetSubscription = this.fleetService.selectedFleet.subscribe(async id => {
            if (id != null) {
                this.fleetId = id;
                this.selectedBasisSubscription?.unsubscribe();
                this.selectedBasisSubscription = this.fleetService.get(id).subscribe(fleet => {
                    this.basisId = fleet?.base.id;
                    // Skip search while loading, because we have a fleet + customer change and customer change handling
                    // will manage this. (We need to do new API Calls, so this search would run to early)
                    if (!this.showLoading) {
                        this.search();
                    }
                })
            }
        });

        await this.loadAllData();
        this.search();

        this.dataSource = new MatTableDataSource(this.searchResults);
        this.dataSource.paginator = this.paginator;
        this.showLoading = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.searchResults;
    }

    public optionClicked(event: Event, filter: SearchFilter) {
        event.stopPropagation();
        this.toggleSelection(filter);
    }

    public toggleSelection(filter: SearchFilter) {
        const index = this.selectedFilters.indexOf(filter);
        if (index === -1) {
            this.selectedFilters.push(filter);
        } else {
            this.selectedFilters.splice(index, 1);
        }
        this.search();
    }

    removeFilter(filter: SearchFilter): void {
        const index = this.selectedFilters.indexOf(filter);
        if (index !== -1) {
            this.selectedFilters.splice(index, 1);
            this.search();
        }
    }

    displayFilters(value: SearchFilter[] | string): string | undefined {
        return this.lastFilter;
    }

    public set searchAllFleets(value: boolean) {
        this._searchAllFleets = value;
        this.search();
    }

    public get searchAllFleets() {
        return this._searchAllFleets;
    }

    public search() {
        if (!this.keyword) {
            return;
        }

        if (this.advanced && this.keyword.includes("=")) {
            const res = this.keyword.split("=", 2);
            const filterList = res[0].includes(".")? this.searchFilterConfig.fieldFilters: this.searchFilterConfig.allFilter;
            const filter = this.searchFilterConfig.find(res[0], filterList);
            if (filter && !this.selectedFilters.includes(filter)) {
                this.selectedFilters.push(filter);
            }
            this.keyword = res[1];
        }

        this.showResultCount = false;
        const keys = this.keyword.trim().split(" ");
        if(keys.length === 1 && keys[0] === '') {
            return;
        }

        this.searchResults = [];
        for (const vehicle of this.vehicles) {
            if (vehicle.canView && (this._searchAllFleets || vehicle.fleet.id === this.fleetId)) {
                this.searchResults.push(this.searchObject(vehicle, "vehicle", vehicle.fleet.name));
            }
        }

        for (const evse of this.evses) {
            if (this._searchAllFleets || evse.basis.id === this.basisId) {
                this.searchResults.push(this.searchObject(evse, "evse", this.getFleetNameFromBasisId(evse.basis.id)));
            }
        }

        for (const rfid of this.rfids) {
            if (rfid.canView) {
                this.searchResults.push(this.searchObject(rfid, "rfid", "Global"));
            }
        }

        for (const meter of this.meters) {
            if (this._searchAllFleets || meter.basis?.id === this.basisId) {
                this.searchResults.push(this.searchObject(meter, "meter", this.getFleetNameFromBasisId(meter.basis?.id)));
            }
        }

        for (const ps of this.powerSupplies) {
            if (this._searchAllFleets || ps.baseId === this.basisId) {
                this.searchResults.push(this.searchObject(ps, "powerSupply", this.getFleetNameFromBasisId(ps.baseId)));
            }
        }

        for (const load of this.loads) {
            const basisId = this.powerSupplies.find(ps => ps.id === load.powerSupplyId)?.baseId;
            this.searchResults.push(this.searchObject(load, "load", this.getFleetNameFromBasisId(basisId)));
        }

        for (const solarSystem of this.solarSystems) {
            if (this._searchAllFleets || solarSystem.basis?.id === this.basisId) {
                this.searchResults.push(this.searchObject(solarSystem, "solarSystem", this.getFleetNameFromBasisId(solarSystem.basis?.id)));
            }
        }

        for (const user of this.users) {
            this.searchResults.push(this.searchObject(user, "user", "Global"));
        }


        this.searchResults = this.searchResults.filter(r => hasAnyMatch(r.matches));
        this.searchResults.sort((a, b) => compareMatches(a.matches, b.matches));
        this.showResultCount = true;
        this.dataSource.data = this.searchResults;
    }

    public searchObject(object: any, searchType: SearchType, fleetName: string | undefined): SearchResult {
        const fieldResults = this.searchService.searchFields(this.keyword, object, searchType, this.selectedFilters);

        return {
            object: object,
            matches: {
                fullWord: fieldResults.map(r => r.matches.fullWord).reduce((sum, count) => sum + count, 0),
                full: fieldResults.map(r => r.matches.full).reduce((sum, count) => sum + count, 0),
                partial: fieldResults.map(r => r.matches.partial).reduce((sum, count) => sum + count, 0)
            },
            resultType: searchType,
            links: this.getSupportingLink(searchType, object),
            fields: fieldResults.sort((a, b) => compareMatches(a.matches, b.matches)),
            fleetName: fleetName
        }
    }

    public createTitle(object: SearchResult): string {
        return this.searchDataConfig.getTitle(object.resultType, object.object);
    }

    public createRouterLink(object: SearchResult): string {
        return this.searchDataConfig.getRouterLink(object.resultType, object.object.id)
    }

    public createSupportingLinks(object: SearchResult) {
        let result = "";
        object.links.forEach(link => {
            result += link + "<br>";
        });
        return result;
    }

    public textChanged(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.updateUrl();
            this.search();
        }
    }

    public updateUrl() {
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: { search: encodeURIComponent(this.keyword) },
                queryParamsHandling: 'merge'
            }
        );
    }

    public getTotalSearchResultCount(): number {
        return this.searchResults.length;
    }

    public async edit(element: SearchResult): Promise<void> {
        switch (element.resultType) {
            case "rfid":
                await this.rfidService.showEditDialog(element.object);
                break;
            case "user":
                await this.userService.showEditDialog(element.object);
                break;
            case "vehicle":
                await this.vehicleService.showEditDialog(element.object);
                break;
            case "meter":
                await this.meterService.showEditDialog(element.object);
                break;
            case "evse":
                await this.evseService.showEditDialog(element.object, this.powerSupplies);
                break;
            case "solarSystem":
                await this.solarSystemService.showEditDialog(element.object, {
                    // @ts-ignore
                    possibleMeters: this.meters.filter(meter => meter.basis?.id === element.object.basis.id)?.sort((a, b) => a.name.localeCompare(b.name)),
                    // @ts-ignore
                    possiblePowerSupplys: this.powerSupplies.filter(ps => ps.baseId === element.object.basis.id)?.sort((a, b) => a.name.localeCompare(b.name)),
                    basisId: element.object.basis.id
                });
                break;
            case "powerSupply":
                const basis = await this.basisService.getPromise(element.object.baseId);
                await this.powerSupplyService.showEditDialog(element.object, basis);
                break;
            case "load":
                // @ts-ignore
                await this.loadService.showEditDialog(element.object, this.powerSupplies.filter(ps => ps.baseId === this.basisId)?.sort((a, b) => a.name.localeCompare(b.name)));
                break;
        }
        await this.loadAllData();
        this.search();
    }

    public async delete(element: SearchResult): Promise<void> {
        switch (element.resultType) {
            case "rfid":
                await this.rfidService.showDeleteDialog(element.object, {});
                break;
            case "user":
                await this.userService.showDeleteDialog(element.object, {});
                break;
            case "vehicle":
                await this.vehicleService.showDeleteDialog(element.object, {});
                break;
            case "meter":
                await this.meterService.showDeleteDialog(element.object, {});
                break;
            case "solarSystem":
                await this.solarSystemService.showDeleteDialog(element.object, {});
                break;
            case "evse":
                await this.evseService.showDeleteDialog(element.object, {});
                break;
            case "load":
                await this.loadService.showDeleteDialog(element.object, {});
                break;
        }
        await this.loadAllData();
        this.search();
    }

    public async editPw(element: SearchResult): Promise<void> {
        await this.userService.showEditPwDialog(element.object);
        this.search();
    }

    public async addSolarPanel(element: SearchResult): Promise<void> {
        await this.solarPanelService.showNewDialog([element.object]);
    }

    public getValidations(evse: Evse) {
        if(evse.liveData.validationResult.some(v => v.validationResult === "INVALID" || v.validationResult === "VALIDATION_ERROR")) {
            return evse.liveData.validationResult;
        }
        return null;
    }

    public handleValidation(evse: Evse) {
        this.evseService.showValidationDialog(evse);
    }

    public async easeeLogin(evse: Evse): Promise<void> {
        await this.evseService.showEaseeLogin(evse);
    }

    public showUnlockButton(evse: Evse): boolean {
        return !!evse.liveData.evsePlugged && evse.authEnabled;
    }

    public async unlock(evse: Evse): Promise<void> {
        try {
            const response = await this.evseService.disableAuthTemp(evse.id);
            if(response.success) {
                this.toastrService.success("Ladepunkt wird freigegeben, bis das Auto abgesteckt wird.");
            } else {
                this.toastrService.warning("Fehler beim freigeben des Ladepunktes");
                console.error(response.result);
            }
        } catch (e) {
            this.toastrService.warning("Fehler beim freigeben des Ladepunktes");
        }
    }

    public async triggerSequence(evse: Evse) {
        try {
            const result = await this.evseService.triggerSequence(evse.id);
            if(result.success) {
                this.toastrService.success("Sequenz zur Fahrzeugerkennung wird gestartet.")
            } else {
                this.toastrService.error("Fehler beim starten der Sequenz.")
                console.error(result.result)
            }
        } catch (e) {
            this.toastrService.error("Fehler beim starten der Sequenz.")
        }
    }

    public async actions(evse: Evse) {
        await this.evseService.showActionDialog(evse);
    }

    private getSupportingLink(type: string, object: any): Set<string> {
        const link = this.searchDataConfig.getRoutingLinkReference(type, object);
        const result = new Set<string>();
        link?.forEach(link => {
            result.add("<i><a href='" + link.value + "'>" + link.key + "</a></i>");
        });
        return result;
    }

    private async loadAllData(): Promise<void> {
        const requests: Promise<unknown>[] = [];
        requests.push(this.loadService.getAllPromise().then(ls => this.loads = ls));
        requests.push(this.solarSystemService.getAllPromise().then(ss => this.solarSystems = ss));
        requests.push(this.vehicleService.getAllPromise().then(vs => this.vehicles = vs));
        requests.push(this.evseService.getAllPromise().then(es => this.evses = es));
        requests.push(this.rfidService.getAllPromise().then(rs => this.rfids = rs));
        requests.push(this.meterService.getAllPromise().then(ms => this.meters = ms));
        requests.push(this.powerSupplyService.getAllPromise().then(pss => this.powerSupplies = pss));
        requests.push(this.userService.getAllPromise().then(us => this.users = us));

        await Promise.all(requests);
    }

    private getFleetNameFromBasisId(basisId: number | undefined): string | undefined {
        if (basisId) {
            return this.fleets.find(f => f.base.id === basisId)?.name;
        }
        return undefined;
    }

    ngOnDestroy(): void {
        if (this.selectedFleetSubscription) {
            this.selectedFleetSubscription.unsubscribe();
        }
        if (this.selectedBasisSubscription) {
            this.selectedBasisSubscription.unsubscribe();
        }
        if (this.headerKeyObservable) {
            this.headerKeyObservable.unsubscribe();
        }
    }
}
