<div class="d-flex flex-column">
    <mat-dialog-content>
        <div class="dialog-head">
            <h2 class="mat-h2">Erweiterte Filter</h2>
            <mat-icon class="close-icon" (click)="onNoClick()">close</mat-icon>
        </div>
        <table>
            <thead>
                <td>Status</td>
                <td></td>
                <td>Stecker Zustand</td>
                <td></td>
                <td>Lademodus</td>
            </thead>
            <tr>
                <td>
                    <div class="align-top">
                        @for(filter of data; track filter.filter) {
                            @if (filter.category === EvseFilterCategory.STATE) {
                                <mat-checkbox [(ngModel)]="filter.active">
                                    @if(filter.icon!=null) {
                                        @if(filter.svg) {
                                            <mat-icon class="filter-icon" [svgIcon]="filter.icon"></mat-icon>
                                        } @else {
                                            <mat-icon class="filter-icon">{{filter.icon}}</mat-icon>
                                        }
                                    } {{filter.displayName}}</mat-checkbox>
                                <br>
                            }
                        }
                    </div>
                </td>
                <td>
                    <mat-divider vertical></mat-divider>
                    <mat-divider vertical></mat-divider>
                </td>
                <td>
                    <div class="align-top">
                        @for(filter of data; track filter.filter) {
                            @if (filter.category === EvseFilterCategory.PLUG_STATE) {
                                <mat-checkbox [(ngModel)]="filter.active"><mat-icon class="filter-icon iblack" [svgIcon]="filter.icon"></mat-icon> {{filter.displayName}}</mat-checkbox>
                                <br>
                            }
                        }
                    </div>
                </td>
                <td>
                    <mat-divider vertical></mat-divider>
                    <mat-divider vertical></mat-divider>
                    <mat-divider vertical></mat-divider>
                    <mat-divider vertical></mat-divider>
                </td>
                <td>
                    <div class="align-top">
                        @for(filter of data; track filter.filter) {
                            @if (filter.category === EvseFilterCategory.CHARGE_STATE) {
                                @if(filter.svg) {
                                    <mat-checkbox [(ngModel)]="filter.active"><mat-icon class="filter-icon" [svgIcon]="filter.icon"></mat-icon> {{filter.displayName}} Aktiv</mat-checkbox>
                                    <br>
                                    <mat-checkbox [(ngModel)]="filter.inactive"><mat-icon class="filter-icon igray" [svgIcon]="filter.icon"></mat-icon> {{filter.displayName}} Inaktiv</mat-checkbox>
                                    <br>
                                } @else {
                                    <mat-checkbox [(ngModel)]="filter.active"><mat-icon class="filter-icon iblack">{{filter.icon}}</mat-icon> {{filter.displayName}} Aktiv</mat-checkbox>
                                    <br>
                                    <mat-checkbox [(ngModel)]="filter.inactive"><mat-icon class="filter-icon igray">{{filter.icon}}</mat-icon> {{filter.displayName}} Inaktiv</mat-checkbox>
                                    <br>
                                }

                            }
                        }
                    </div>
                </td>
            </tr>
        </table>


    </mat-dialog-content>
    <mat-dialog-actions class="buttons d-flex flex-row justify-content-end gap-3">
        <button
            mat-raised-button color="primary"
            type="submit"
            [mat-dialog-close]="filters()"
            cdkFokusInitial
        >
            Speichern
        </button>
        <button
            mat-raised-button color="accent"
            type="submit"
            (click)="onNoClick()"
        >
            Verwerfen
        </button>
    </mat-dialog-actions>

</div>
